import React from 'react'
import blogData from "../data/blog.json"
import "../Styles/blog.css"

function BlogSidebar() {
    return (
        <>
            <div className="blog-search" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                <div className="blog-search-top">
                    <h1>Search</h1>
                </div>
                <div className="blog-search-bottom">
                    <input type="text" placeholder='Search' className='search-box' name='search' />
                    <div className="search-icon-box">
                        <img src={require("../assets/blog/search-icon.png")} alt="search-icon.png" />
                    </div>
                </div>
            </div>
            <div className="blog-categories" data-aos="fade-up" data-aos-delay="1800" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                <div className="blog-categories-top">
                    <h1>Categories</h1>
                </div>
                <div className="blog-categories-menus">
                    <ul className='list-unstyled m-0'>
                        <li><a href="/mobileapp">Mobile Development</a></li>
                        <li><a href="/webdeveloper">Web Development</a></li>
                        <li><a href="/digitalmarking">Digital Marketing</a></li>
                        <li><a href="#">Website Design</a></li>
                    </ul>
                </div>
            </div>
            <div className="trending-post" data-aos="fade-up" data-aos-delay="2800" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                <div className="trending-post-top">
                    <h1>Trending Post</h1>
                </div>
                <div className="trending-post-body">
                    {
                        blogData.slice(0, 3).map((item, index) => (
                            <div className='trending-post-content' key={index}>
                                <div className="trending-post-img">
                                    <img src={`${window.location.origin}/assets/blog/${item.image['img-1']}`} alt={item.image['img-1']} />
                                </div>
                                <div className="trending-post-info">
                                    <h1>{item.title}</h1>
                                    <p>{item.date}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default BlogSidebar