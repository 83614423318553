import React, { useEffect, useState } from "react";
import "../Styles/header.css";

function Header({ toggleDrawer, isDrawerOpen }) {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsHeaderFixed(true);
       
      } else {
        setIsHeaderFixed(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <nav className={`navbar navbar-expand-lg navbar-light ${isHeaderFixed ? 'fixed-header' : ''}`}>
        <div className="header-container">
          <div className="header-logo">
            <a href="/"><img src={require("../assets/header/headerlogo.png")} alt="headerlogo.png" /></a>
          </div>
          {
            !isDrawerOpen ? (
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleDrawer}>
                <i className="fa-solid fa-bars"></i>
              </button>
            ) : (
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleDrawer}>
                <i className="fa-solid fa-xmark close-icon"></i>
              </button>
            )
          }
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <hr />
            <ul className="navbar-nav main-menu">
              <li className="nav-item">
                <img src={require('../assets/header/home-icon.png')} alt="home-icon.png" />
                <a href="/" className={`nav-link ${window.location.pathname === "/" ? 'active-link' : ''}`}>Home</a>
              </li>
              <li className="nav-item dropdown">
                <img src={require('../assets/header/service-icon.png')} alt="service-icon.png" />
                <a href="#" className={`nav-link dropdown-toggle ${(window.location.pathname === "/mobileapp" || window.location.pathname === "/software" || window.location.pathname === "/webdeveloper" || window.location.pathname === "/nftdeveloper" || window.location.pathname === "/uiuxdesign" || window.location.pathname === "/digitalmarking") ? 'active-link' : ''}`} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">services</a>
                <ul className="dropdown-menu sub-menu"  aria-labelledby="navbarDropdown">
                  <li><a className="dropdown-item" href="/mobileapp">Mobile App Development</a></li>
                  {/* <li><a className="dropdown-item" href="/software">Software Development</a></li> */}
                  <li><a className="dropdown-item" href="/webdeveloper">Web Development</a></li>
                  <li><a className="dropdown-item" href="/nftdeveloper">NFT development</a></li>
                  <li><a className="dropdown-item" href="/uiuxdesign">Ui/Ux Design</a></li>
                  <li><a className="dropdown-item" href="/digitalmarking">Digital marketing</a></li>
                </ul>
              </li>
              <li className="nav-item">
                <img src={require('../assets/header/portfolio-icon.png')} alt="portfolio-icon.png" />
                <a href="/portfolio" className={`nav-link ${window.location.pathname === "/portfolio" ? 'active-link' : ''}`}>portfolio</a>
              </li>
              {/* <li className="nav-item">
                <img src={require('../assets/header/blog-icon.png')} alt="blog-icon.png" />
                <a href="/blog" className={`nav-link ${window.location.pathname.split("/")[1] === "blog" ? 'active-link' : ''}`}>blog</a>
              </li> */}
              <li className="nav-item">
                <img src={require('../assets/header/about-icon.png')} alt="about-icon.png" />
                <a href="/about" className={`nav-link ${window.location.pathname === "/about" ? 'active-link' : ''}`}>about</a>
              </li>
              <li className="nav-item">
                <a href="/contactUs" className={`nav-link ${window.location.pathname === "/contactUs" ? 'active-link' : ''}`}>contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav >
    </>
  );
}

export default Header;
