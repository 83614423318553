import React from "react";
import Header from "../components/Header";
import "../Styles/mobileapp.css";
import mobileservice from "../data/uiux.json";
import Footer from "../components/Footer";
import Slider from "react-slick";

function Uiuxdesign() {
  var slider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Header />
      <section className="S-section-1">
        <div className="home-container">
          <div className="row">
            <div
              data-aos="fade-right"
              data-aos-duration="700"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6 "
            >
              <div className="S-section1-content">
                <h2>UI/UX DESSIGNIG</h2>
                <p>
                  At Rutilant, we specialize in UI/UX design to transform your
                  digital presence into a captivating and user-centric journey.
                  Our expert designers craft visually stunning interfaces and
                  seamless user experiences, ensuring your audience engages
                  effortlessly with your product or platform. Whether it's a
                  website, application, or any digital interface, Rutilant's
                  UI/UX design services prioritize aesthetics, functionality,
                  and user satisfaction. Partner with us to elevate your brand
                  and leave a lasting impression in the digital realm.
                </p>
                <button>Talk to our expert</button>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section1-img">
                <img
                  src={require("../assets/serivce/ui.png")}
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-2">
        <div className="service-container">
          <div className="row">
            <div
              data-aos="fade-right"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section2-content">
                <h4>OUR SERVICES</h4>
                <h2>Our UI/UX DESSINGING</h2>
                <p>
                  Rutilant's UI/UX Design Services are dedicated to crafting
                  visually striking digital interfaces. Our expert designers
                  blend creativity with functionality, ensuring your digital
                  presence captivates users from the first glance. Elevate your
                  brand aesthetics and user experience with our visually
                  appealing designs.
                </p>
                <p>
                  We prioritize intuitive design to create seamless user
                  experiences. From wireframes to the final product, our UI/UX
                  experts meticulously plan and execute every detail. Trust us
                  to enhance user satisfaction and interaction, making your
                  digital platforms effortlessly navigable.
                </p>
                <p>
                  We transform your ideas into impactful digital experiences.
                  Whether it's a website or application, our team ensures that
                  every interaction reflects your brand identity and leaves a
                  lasting impression. Partner with us for design solutions that
                  resonate with your audience.
                </p>
                {/* <p>
                  Publishing the app: Native app developers publish the app on
                  the app store for users to download and install on their
                  devices.
                </p> */}
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              {mobileservice.map((item, index) => (
                <div className="service-box" key={index}>
                  <div className="d-flex">
                    <img
                    className="img-fluid imageweb"
                      src={`${window.location.origin}/assets/service/${item.image}`}
                      alt={item.image}
                    />
                    <h6>{item.title}</h6>
                  </div>
                  <p>
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-3">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section3-title">
              <h4>Technology Stack</h4>
              <h2>Technologies & Platforms We Work With</h2>
            </div>
          </div>
          <div className="row">
            <div className=" col-sm-6 col-md-10 col-lg-6 col-xl-10 m-auto">
              <Slider {...slider}>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/af-icon.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>After Effect</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/ai-icon.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>After Effect</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/ps-icon.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Photoshop</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/xd-icon.png")}
                    alt=""
                    srcset=""
                  />
                  <h6 style={{ lineHeight: "100%" }}>Experience Design</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/figma-logo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Figma</h6>
                </div>
                {/* <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/xd-icon.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Experience Design</h6>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-4">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section4-title">
              <h4>OUR WORK</h4>
              <h2>ui/ux designing PROJECT</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-1.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-2.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-3.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-4.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Uiuxdesign;
