import React, { useState } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import '../Styles/contactUs.css'
import { FaLocationDot } from "react-icons/fa6";
import { BsEnvelopeFill } from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";

function ContactUs() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen)
    }
    return (
        <>
            <Header toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
            <section className='contact-us-part'>
                <div className="contact-us-container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className='title-part' data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000">CONTACT US</h1>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 contact-col">
                            <div className="map-section">
                                <iframe title='Rutilant_location' className='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14875.311140205971!2d72.8794559!3d21.2386766!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f38c2fdd361%3A0xb0f6e9295cc2b0b4!2sRutilant%20Technology!5e0!3m2!1sen!2sin!4v1678356136886!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000" ></iframe>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 contact-col">
                            <div className="contact-us-info-content">
                                <div className="address-part" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500">
                                    <div className="address-icon-box">
                                        <FaLocationDot className='location-icon' />
                                    </div>
                                    <div className="address-info">
                                        <h1>Address</h1>
                                        <p>230, Atlanta Shopping Mall, Near Sudama Chowk, Mota Varachha, Surat,394101</p>
                                    </div>
                                </div>
                                <div className="email-part" data-aos="fade-left" data-aos-delay="200" data-aos-duration="500">
                                    <div className="email-icon-box">
                                        <BsEnvelopeFill className='email-icon' />
                                    </div>
                                    <div className="email-info">
                                        <h1>Email</h1>
                                        <p>info@rutilanttechnology.com</p>
                                    </div>
                                </div>
                                <div className="phone-part" data-aos="fade-left" data-aos-delay="200" data-aos-duration="400">
                                    <div className="phone-icon-box">
                                        <FaPhone className='phone-icon' />
                                    </div>
                                    <div className="phone-info">
                                        <h1>Phone</h1>
                                        <p>+91 9785597455</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-us-form ">
                <div className="contact-us-container ">
                    <form method="post">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 ">
                                <h1 className='title-part' data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000">Contact Form</h1>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-delay="700" data-aos-duration="500">
                                <input type="text" placeholder='Name' name='name' autoComplete='false' />
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-delay="700" data-aos-duration="500">
                                <input type="email" placeholder='Email' name='email' autoComplete='false' />
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-delay="700" data-aos-duration="500">
                                <input type="text" placeholder='Phone' name='phone' autoComplete='false' />
                            </div>
                            <div className="col-sm-12 col-xl-9" data-aos="fade-up" data-aos-delay="700" data-aos-duration="500">
                                <textarea name="message" className='message' placeholder='Message' ></textarea>
                            </div>
                            <div className="col-sm-12 text-center" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                                <button type='submit' className='submit-btn'>Send</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ContactUs