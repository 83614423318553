import React from "react";
import Header from "../components/Header";
import "../Styles/mobileapp.css";
import mobileservice from "../data/mobileservice.json";
import Footer from "../components/Footer";
import Slider from "react-slick";

function Mobileapp() {
  var slider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Header />
      <section className="S-section-1">
        <div className="service-container">
          <div className="row">
            <div
              data-aos="fade-right"
              data-aos-duration="700"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section1-content">
                <h2>MOBILE APP DEVELOPMENT COMPANY</h2>
                <p>
                  Rutilant Mobile Solutions is a premier mobile app development
                  company committed to creating innovative and user-centric
                  mobile applications. Our skilled team of developers excels in
                  crafting customized solutions for iOS, Android, and
                  cross-platform applications, ensuring seamless functionality
                  and exceptional user experiences. From conceptualization to
                  deployment, we leverage the latest technologies and industry
                  best practices to deliver high-performance, scalable, and
                  visually stunning mobile apps. Whether you're a startup aiming
                  to launch a groundbreaking app or an established enterprise
                  seeking to expand your mobile presence, Rutilant Mobile
                  Solutions is your reliable partner for turning ideas into
                  successful and engaging mobile experiences.
                </p>
                <button>Talk to our expert</button>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section1-img">
                <img
                  src={require("../assets/home/application.png")}
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-2">
        <div className="service-container">
          <div className="row">
            <div
              data-aos="fade-right"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section2-content">
                <h4>OUR SERVICES</h4>
                <h2>Our Mobile App Development Services</h2>
                <p>
                  Designing the user interface: Native app developers design the
                  app's user interface to ensure that it is visually appealing,
                  easy to use, and provides a positive user experience.
                </p>
                <p>
                  Developing the app's functionality: Native app developers use
                  programming languages such as Swift or Java to build the app's
                  functionality, including features such as data storage,
                  networking, and user interactions.
                </p>
                <p>
                  Testing and debugging: Native app developers test the app
                  thoroughly to ensure that it works as intended, and debug any
                  issues that arise during testing.
                </p>
                <p>
                  Publishing the app: Native app developers publish the app on
                  the app store for users to download and install on their
                  devices.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              {mobileservice.map((item, index) => (
                <div className="service-box" key={index}>
                  <div className="d-flex">
                    <img
                      className="imageweb"
                      src={`${window.location.origin}/assets/service/${item.image}`}
                      alt={item.image}
                    />
                    <h6>{item.title}</h6>
                  </div>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-3">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section3-title">
              <h4>Technology Stack</h4>
              <h2>Technologies & Platforms We Work With</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-sm-6 col-md-10 col-lg-6  col-xl-10 m-auto">
              <Slider {...slider}>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/fluterlogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Flutter</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    // src={require("../assets/serivce/ios.png")}
                    src={`${window.location.origin}/assets/service/ios.png`}
                    alt=""
                    srcset=""
                  />
                  <h6>IOS</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/android_LOGO.png`}
                    // src={require("../assets/serivce/android_LOGO.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Android</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/java.png`}
                    // src={require("../assets/serivce/android_LOGO.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>JAVA</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/kotlin.png`}
                    // src={require("../assets/serivce/android_LOGO.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Kotlin</h6>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-4">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section4-title">
              <h4>OUR WORK</h4>
              <h2>MOBILE APP DEVELOPMENT PROJECT</h2>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-1.png")}
                  alt="" className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-2.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-5.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  {/* <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a> */}
                  <h6 data-aos="h6-animation">TO-DO LIST</h6>
                  <h1 data-aos="h1-animation">TRACK YOUR DAILY WORKS</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-4.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  {/* <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a> */}
                  <h6 data-aos="h6-animation">CRICKET</h6>
                  <h1 data-aos="h1-animation">MAKE YOUR TEAM</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Mobileapp;
