import React, { useEffect } from 'react'
import BlogSidebar from '../components/BlogSidebar'
import { useParams } from 'react-router-dom'
import "../Styles/blogDetails.css"
import blogData from "../data/blog.json"
import Header from '../components/Header'
import Footer from '../components/Footer'
import BlogCommon from '../components/BlogCommon'

function BlogDetails() {
    const { blogId } = useParams()
    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
        var reveals1 = document.querySelectorAll(".reveal-1");
        for (var i = 0; i < reveals1.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals1[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
                reveals1[i].classList.add("active");
            } else {
                reveals1[i].classList.remove("active");
            }
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", reveal);
    }, [])
    return (
        <div className='main-blog-details-sectetion'>
            <Header />
            <section className='blog-details-part'>
                <div className="blog-details-container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6">
                            {
                                blogData.map((item, index) => {
                                    if (index == blogId) {
                                        return (
                                            <div className="blog-body" key={index}>
                                                <div className="blog-image">
                                                    <img src={`${window.location.origin}/assets/blog/${item.image['img-2']}`} alt={item.image['img-2']} data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1000" data-aos-easing="ease-in-sine" />
                                                </div>
                                                <div className="reveal fade-left">
                                                    <div className="blog-content">
                                                        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000" data-aos-once="true">By {item.author}. on {item.date}</p>
                                                        <h1 data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000" data-aos-once="true">{item.title}</h1>
                                                    </div>
                                                </div>
                                                <div className="blog-description" data-aos="fade-up" data-aos-delay="2000" data-aos-duration="1000">
                                                    <p>{item.description}</p>
                                                </div>
                                                <div className="why-use-part">
                                                    <h1 className="reveal-1 why-use-part-fade-left">Why do we use it?</h1>
                                                    <p data-aos="fade-up" data-aos-delay="2000" data-aos-duration="1000">{item.whyUse}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <BlogSidebar />
                        </div>
                    </div>
                </div>
            </section>
            <BlogCommon />
            <Footer />
        </div>
    )
}

export default BlogDetails