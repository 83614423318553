import { Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import About from "./Pages/About";
import Portfolio from "./Pages/Portfolio";
import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import ContactUs from "./Pages/ContactUs";
import BlogDetails from "./Pages/BlogDetails";
import Mobileapp from "./subpages/Mobileapp";
import Software from "./subpages/Software";
import Webdevelop from "./subpages/Webdevelop";
import Nftdeveloper from "./subpages/Nftdeveloper";
import Digitalmarking from "./subpages/Digitalmarking";
import Uiuxdesign from "./subpages/Uiuxdesign";
import NotFound from "./components/NotFound";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<NotFound/>}/> */}
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/mobileapp" element={<Mobileapp />} />
        {/* <Route path="/software" element={<Software/>}/> */}
        <Route path="/webdeveloper" element={<Webdevelop />} />
        <Route path="/nftdeveloper" element={<Nftdeveloper />} />
        <Route path="/digitalmarking" element={<Digitalmarking />} />
        <Route path="/uiuxdesign" element={<Uiuxdesign />} />
        {/* <Route path="/blog" element={<Blog />} /> */}
        <Route path="/blog/:blogId" element={<BlogDetails />} />
        <Route path="/contactUs" element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
// {`${window.location.origin}/assets/about/${item.image}`}