import React from "react";
import Header from "../components/Header";
import "../Styles/mobileapp.css";
import mobileservice from "../data/nftdata.json";
import Footer from "../components/Footer";
import Slider from "react-slick";

function Nftdeveloper() {
  var slider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Header />
      <section className="S-section-1">
        <div className="home-container">
          <div className="row">
            <div
              data-aos="fade-right"
              data-aos-duration="700"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6 "
            >
              <div className="S-section1-content">
                <h2>NFT DEVELOPMENT COMPANY</h2>
                <p>
                  Rutilant NFT Solutions offers a comprehensive range of
                  services to facilitate seamless NFT development. Our expert
                  team specializes in designing and deploying custom NFT
                  platforms, ensuring secure integration with cutting-edge
                  blockchain technology. We provide end-to-end solutions, from
                  smart contract development for NFT creation and management to
                  personalized NFT marketplace launches. Whether you're an
                  artist, business, or creator looking to tokenize and trade
                  digital assets, our services prioritize transparency,
                  security, and user experience, making Rutilant NFT Solutions
                  your trusted partner in navigating the dynamic landscape of
                  decentralized digital ownership.
                </p>
                <button>Talk to our expert</button>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section1-img">
                <img
                  src={require("../assets/serivce/ntf.png")}
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-2">
        <div className="service-container">
          <div className="row">
            <div
              data-aos="fade-right"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section2-content">
                <h4>OUR SERVICES</h4>
                <h2>Our NFT Development Services</h2>
                <p>
                  Rutilant NFT Solutions is a pioneering force in the realm of
                  digital innovation, specializing in NFT development. We offer
                  a comprehensive suite of services tailored to meet the growing
                  demands of the NFT ecosystem. Our expert team excels in
                  crafting bespoke NFT platforms, ensuring seamless integration
                  with blockchain technology. From conceptualization to
                  deployment, we prioritize transparency, security, and user
                  experience, empowering artists, businesses, and creators to
                  tokenize and trade their digital assets with confidence.
                </p>
                <p>
                  At the core of our NFT development expertise lies a commitment
                  to leveraging cutting-edge blockchain frameworks. We
                  specialize in smart contract development, providing a robust
                  foundation for the creation and management of NFTs. By
                  combining technological prowess with creative insight, we
                  enable clients to explore the vast potential of NFTs, whether
                  it's launching a personalized NFT marketplace, developing
                  digital collectibles, or integrating blockchain solutions into
                  diverse industries. Rutilant NFT Solutions is dedicated to
                  driving the adoption of NFTs and revolutionizing digital
                  ownership experiences.
                </p>
                <p>
                  As a forward-thinking NFT development company, Rutilant NFT
                  Solutions understands the transformative power of
                  decentralized digital ownership. We navigate the complex
                  landscape of blockchain technology to deliver end-to-end
                  solutions that redefine how assets are represented, traded,
                  and valued in the digital realm. Whether you're an artist
                  seeking to tokenize your work or a business exploring the
                  possibilities of blockchain, our expertise ensures a seamless
                  and secure journey into the exciting world of NFTs. Trust
                  Rutilant NFT Solutions to be your reliable partner in
                  embracing the future of digital asset ownership.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              {mobileservice.map((item, index) => (
                <div className="service-box" key={index}>
                  <div className="d-flex">
                    <img
                      src={`${window.location.origin}/assets/service/${item.image}`}
                      alt={item.image}
                    />
                    <h6>{item.title}</h6>
                  </div>
                  <p>
                    Our team of seasoned professionals is committed to
                    delivering customised solutions tailored to your unique
                    needs. We pride ourselves on our ability to provide with
                    every project we undertake. Our team of solutions tailored
                    to your unique needs.{" "}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-3">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section3-title">
              <h4>Technology Stack</h4>
              <h2>Technologies & Platforms We Work With</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-sm-6 col-md-10 col-lg-6 col-xl-10 m-auto">
              <Slider {...slider}>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/solidity.png`}
                    // src={require("../assets/serivce/fluterlogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Solidity</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/rust.png`}
                    alt=""
                    srcset=""
                  />
                  <h6>Rust</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/solidity.png`}
                    // src={require("../assets/serivce/fluterlogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Solidity</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/rust.png`}
                    alt=""
                    srcset=""
                  />
                  <h6>Rust</h6>
                </div>
                {/* <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/vuelogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>vue js </h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/htmllogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>html</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/csslogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>css</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/nativelogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>React Native</h6>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="S-section-4">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section4-title">
              <h4>OUR WORK</h4>
              <h2>nft DEVELOPMENT PROJECT</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-1.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-2.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-3.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-4.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
}

export default Nftdeveloper;
