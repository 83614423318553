import React, { useState } from "react";
import "../Styles/about.css";
import aboutData from "../data/about.json";
import aboutExpertData from "../data/aboutExpert.json";
import Footer from "../components/Footer";
import Header from "../components/Header";
// React Slick Carousel Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// Animation Library
import "aos/dist/aos.css";
// Counter Library
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

function About() {
  const [counterStart, setCounterStart] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Slider Options
  var expertMemberSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: true,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: true,
        },
      },
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: true,
        },
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          centerPadding: "60px",
          // slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 414,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          centerPadding: "60px",
          dots: true,
        },
      },
      {
        breakpoint: 375,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          centerPadding: "60px",
          dots: false,
        },
      },
      {
        breakpoint: 361,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          centerPadding: "60px",
          // slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <Header toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
      <section className="about-section-1">
        <div className="about-container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="about-content">
                <h1
                  data-aos="fade-down"
                  data-aos-delay="700"
                  data-aos-duration="500"
                >
                  Discover The Story Behind Our Passionate{" "}
                  <span>| Team And Our Mission.</span>
                </h1>
                <p
                  data-aos="fade-up"
                  data-aos-delay="700"
                  data-aos-duration="500"
                >
                  Explore the compelling narrative of our dedicated team and the
                  driving force behind our mission at Rutilant Technology. With
                  a passionate and talented group, we are committed to creating
                  a positive impact through innovation and excellence. Join us
                  on our journey as we strive to make a meaningful difference in
                  development.
                </p>
                <button
                  className="talk-expert-btn"
                  data-aos="fade-up"
                  data-aos-delay="700"
                  data-aos-duration="500"
                >
                  <a href="#" className="talk-expert-btn">
                    Talk to our expert
                  </a>
                </button>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="about-img">
                <img
                  src={require("../assets/about/about-mission.png")}
                  alt="about-mission.png"
                  data-aos="zoom-in"
                  data-aos-delay="500"
                  data-aos-duration="500"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section-2">
        <div className="about-container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="about-img">
                <img
                  src={require("../assets/about/about-achieving.png")}
                  alt="about-achieving.png"
                  data-aos="fade-right"
                  data-aos-delay="400"
                  data-aos-duration="500"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="about-content">
                <h6
                  data-aos="fade-down"
                  data-aos-delay="400"
                  data-aos-duration="500"
                >
                  WHO WE ARE
                </h6>
                <h1
                  data-aos="fade-down"
                  data-aos-delay="400"
                  data-aos-duration="500"
                >
                  achieving SUCCESS THROUGH EFFECTIVE TEAMWORK
                </h1>
                <div
                  className="about-content-paragraph"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="500"
                >
                  <p>
                    We pride ourselves on our unwavering commitment to effective
                    teamwork. Our diverse team brings together individuals from
                    various backgrounds, disciplines, and perspectives, creating
                    a dynamic environment where innovation thrives. We believe
                    that by harnessing the collective power of our team, we can
                    overcome any challenge and achieve remarkable success in our
                    endeavors.
                  </p>
                  <p>
                    Collaboration lies at the heart of everything we do. By
                    fostering open communication, mutual respect, and a shared
                    sense of purpose, we empower our team members to contribute
                    their unique strengths and talents towards common goals.
                    Through effective teamwork, we leverage our collective
                    expertise to drive innovation, solve complex problems, and
                    deliver exceptional results that exceed expectations.
                  </p>
                  <p>
                    Our commitment to effective teamwork extends beyond the
                    confines of our organization. We recognize the importance of
                    building strong partnerships and collaborations with
                    clients, stakeholders, and the broader community. By working
                    together towards shared objectives, we create synergies that
                    enable us to achieve greater impact and make meaningful
                    contributions to the world around us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section-3">
        <div className="about-container">
          <div className="row">
            {aboutData.map((about, index) => (
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6" key={index}>
                <ScrollTrigger
                  onEnter={() => setCounterStart(true)}
                  onExit={() => setCounterStart(false)}
                >
                  <div className="about-content-box">
                    {counterStart && (
                      <CountUp
                        start={0}
                        end={parseInt(about.title, 10)}
                        duration={2}
                        prefix={parseInt(about.title, 10) < 10 ? "0" : ""}
                        suffix="+"
                        className="about-counter-digit"
                      />
                    )}
                    <p>{about.description}</p>
                  </div>
                </ScrollTrigger>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <section className='about-section-4'>
                <div className="about-container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="title-part" data-aos="fade-down" data-aos-delay="400" data-aos-duration="500">
                                <h6>oUR TEAM</h6>
                                <h1>OUR EXPERT TEAM MEMBER</h1>
                            </div>
                            <div className="expert-member-slider" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                                <Slider {...expertMemberSliderSettings} arrows={false}>
                                    {
                                        aboutExpertData.map((item, index) => (
                                            <div className='slider-box' key={index}>
                                                <img src={`${window.location.origin}/assets/about/${item.image}`} alt={item.image} />
                                                <div className="slider-box-content">
                                                    <h1 data-aos="h1-animation">{item.name}</h1>
                                                    <h6 data-aos="h6-animation">{item.description}</h6>
                                                </div>
                                                <span className='border-animation-1'></span>
                                                <span className='border-animation-2'></span>
                                                <span className='border-animation-3'></span>
                                                <span className='border-animation-4'></span>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      <Footer />
    </>
  );
}

export default About;
