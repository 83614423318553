import React, { useEffect, useState } from 'react'
import '../Styles/portfolio.css'
import portfolioData from '../data/portfolio-work.json'
import BlogCommon from '../components/BlogCommon'
import Testimonials from '../components/Testimonials'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Portfolio() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const [portfolioType, setPortfolioType] = useState("all")
    const changePortfolioType = (type) => {
        setPortfolioType(type)
    }
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen)
    }
    return (
        <>
            <Header toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
            <section className='portfolio-section-1'>
                <div className="portfolio-container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="portfolio-content" data-aos="fade-right" data-aos-delay="400" data-aos-duration="500">
                                <h1>Showcasing Our Creative And Professional Achievements</h1>
                                <span>| A Portfolio Of Excellence.</span>
                                <p>Our Team Of Seasoned Professionals Is Committed To Delivering Customised Solutions Tailored To Your Unique Needs. We Pride Ourselves On Our Ability To Provide Exceptional Service And Support, Ensuring That You Receive The Highest Level Of Satisfaction With Every Project We Undertake.</p>
                                <button className='talk-expert-btn'><a href="#" className='talk-expert-btn'>Talk to our expert</a></button>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="portfolio-img">
                                <img src={require("../assets/portfolio/portfolio-img-1.png")} alt="portfolio-img.png" data-aos="fade-left" data-aos-delay="400" data-aos-duration="500" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='portfolio-section-2'>
                <div className="portfolio-container">
                    <div className="row">
                        <div className=" col-sm-12">
                            <div className="title-part" data-aos="fade-down" data-aos-delay="400" data-aos-duration="500">
                                <h6>PORTFLIO</h6>
                                <h1>OUR SIGNIFICANT WORK</h1>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="portfolio-content" data-aos="fade-down" data-aos-delay="400" data-aos-duration="500">
                                <ul className='list-unstyled m-0 portfolio-menus'>
                                    <li className={`${portfolioType.includes("all") ? "active-li" : ""}`}><button onClick={() => changePortfolioType("all")}>All</button></li>
                                    <li className={`${portfolioType.includes("ios") ? "active-li" : ""}`}><button onClick={() => changePortfolioType("ios")}>IOS</button></li>
                                    <li className={`${portfolioType.includes("web") ? "active-li" : ""}`}><button onClick={() => changePortfolioType("web")}>Web Development</button></li>
                                    <li className={`${portfolioType.includes("android") ? "active-li" : ""}`}><button onClick={() => changePortfolioType("android")}>Android</button></li>
                                </ul>
                            </div>
                        </div>
                        {

                            portfolioType == "all" ? (
                                portfolioData.map((item, index) => (
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" key={index}>
                                        <div className="portfolio-card" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                                            <img src={`${window.location.origin}/assets/portfolio/${item.image}`} alt={item.image} className="img-fluid" />
                                            <div className="portfolio-card-content">
                                                {/* <a href="#" className='link-icon' data-aos="link-icon-animation"><i className="fa-solid fa-link"></i></a> */}
                                                <h6 data-aos="h6-animation">{item.title}</h6>
                                                <h1 data-aos="h1-animation">{item.description}</h1>
                                            </div>
                                            <span className='border-animation-1'></span>
                                            <span className='border-animation-2'></span>
                                            <span className='border-animation-3'></span>
                                            <span className='border-animation-4'></span>
                                        </div>
                                    </div>
                                ))
                            ) :
                                (
                                    portfolioData.filter((type) => type.type.includes(portfolioType)).map((item, index) => (
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" key={index}>
                                            <div className="portfolio-card" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                                                <img src={`${window.location.origin}/assets/portfolio/${item.image}`} alt={item.image} className="img-fluid" />
                                                <div className="portfolio-card-content">
                                                    {/* <a href="#" className='link-icon' data-aos="link-icon-animation"><i className="fa-solid fa-link"></i></a> */}
                                                    <h6 data-aos="h6-animation">{item.title}</h6>
                                                    <h1 data-aos="h1-animation">{item.description}</h1>
                                                </div>
                                                <span className='border-animation-1'></span>
                                                <span className='border-animation-2'></span>
                                                <span className='border-animation-3'></span>
                                                <span className='border-animation-4'></span>
                                            </div>
                                        </div>
                                    ))
                                )
                        }
                    </div>
                </div>
            </section>
            {/* <BlogCommon /> */}
            <Testimonials />
            <Footer />
        </>
    )
}

export default Portfolio