import React from "react";
import "../Styles/testimonial.css";
import testimonialData from "../data/testimonial.json";

// React Slick Carousel Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <i
      className={`fa-solid fa-angle-right icon-right`}
      style={{ ...style }}
      onClick={onClick}
    ></i>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <i
      className={`fa-solid fa-angle-left icon-left`}
      style={{ ...style }}
      onClick={onClick}
    ></i>
  );
}

function Testimonials() {
  // Slider Options
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 2600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 428,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 361,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 321,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="testimonial-part">
        <div className="testimonial-container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div
                className="testimonial-content"
                data-aos="fade-right"
                data-aos-delay="700"
                data-aos-duration="500"
              >
                <h6>oUR testimonials</h6>
                <h1>what our clients say </h1>
                <p>
                  Discover the genuine experiences shared by our valued clients
                  in their testimonials. At Rutilant, we take pride in building
                  lasting relationships with those we serve. These testimonials
                  reflect the satisfaction and trust our clients place in our
                  products/services, highlighting the positive impact we've had
                  on their lives and businesses.
                </p>
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-7 col-md-6 col-sm-12"
              data-aos="fade-left"
              data-aos-delay="700"
              data-aos-duration="500"
            >
              <Slider {...settings}>
                {testimonialData.map((item, index) => (
                  <div className="testimonial-box" key={index}>
                    <p>{item.description}</p>
                    <div className="client-details-box">
                      <img
                        src={`${window.location.origin}/assets/image/${item.image}`}
                        alt={item.image}
                      />
                      <div className="client-info">
                        <h1>{item.name}</h1>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
