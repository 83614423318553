import React from 'react'
import '../Styles/footer.css'
import 'aos/dist/aos.css';

function Footer() {
  return (
    <>
      <section className='footer-part-top'>
        <div className="footer-container">
          <div className="row">
            <div className="col-sm-12">
              <div className="footer-content" data-aos="fade-down" data-aos-delay="400" data-aos-duration="500">
                <h1>let's Turn Your Ideas into Reality</h1>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='footer-part'>
        <div className="footer-container">
          <a href="/contactUs" className='contact-btn'>Contact Us</a>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="footer-first-part" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <div className="logo-body">
                  <a href="/"><img src={require("../assets/footer/footer-logo.png")} alt="footer-logo.png" data-aos="fade-down" data-aos-delay="400" data-aos-duration="500" data-aos-easing="ease-out-sine" /></a>
                </div>
                {/* <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="500" data-aos-easing="ease-out-sine">Our team of seasoned professionals is committed to delivering customised solutions tailored to your unique needs. We pride ourselves on our ability to provide with every project we undertake.</p> */}
                <ul className='list-unstyled' data-aos="fade-up" data-aos-delay="400" data-aos-duration="500" data-aos-easing="ease-out-sine">
                  <li><a href="https://www.instagram.com/rutilant_technology/"><img src={require("../assets/footer/instagram-icon.png")} alt="instagram-icon.png" /></a></li>
                  <li><a href="https://www.facebook.com/rutilanttechnology?mibextid=ZbWKwL"><img src={require("../assets/footer/facebook-icon.png")} alt="facebook-icon.png" /></a></li>
                  <li><a href="#"><img src={require("../assets/footer/twitter-icon.png")} alt="twitter-icon.png" /></a></li>
                  <li><a href="https://in.linkedin.com/company/rutilant-technology"><img src={require("../assets/footer/linkedin-icon.png")} alt="linkedin-icon.png" /></a></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="company-part">
                <h4 data-aos="fade-down" data-aos-delay="400" data-aos-duration="500" data-aos-easing="ease-out-sine">Company</h4>
                <ul className='list-unstyled m-0' data-aos="fade-up" data-aos-delay="400" data-aos-duration="500" data-aos-easing="ease-out-sine">
                  <li><a href="/about">About</a></li>
                  <li><a href="#">Services</a></li>
                  <li><a href="/portfolio">Portfolio</a></li>
                  <li><a href="/blog">Blog</a></li>
                  <li><a href="/contactUs">Contact</a></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="service-part">
                <h4 data-aos="fade-down" data-aos-delay="400" data-aos-duration="500" data-aos-easing="ease-out-sine">Services</h4>
                <ul className='list-unstyled m-0' data-aos="fade-up" data-aos-delay="400" data-aos-duration="500" data-aos-easing="ease-out-sine">
                  {/* <li><a href="/software">Software Development</a></li> */}
                  <li><a href="/webdeveloper">Web Development</a></li>
                  <li><a href="/nftdeveloper">NFT Development</a></li>
                  <li><a href="/mobileapp">App Development</a></li>
                  <li><a href="/digitalmarking">Digital Marketing</a></li>
                  <li><a href="/uiuxdesign">UI/UX Desiging</a></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="contact-part">
                <h4 data-aos="fade-down" data-aos-delay="400" data-aos-duration="500" data-aos-easing="ease-out-sine">Contact Info</h4>
                <ul className='list-unstyled m-0' data-aos="fade-up" data-aos-delay="400" data-aos-duration="500" data-aos-easing="ease-out-sine">
                  <li><i className="fa-solid fa-phone"></i>+91 9785597455</li>
                  <li><i className="fa-solid fa-envelope"></i>info@rutilanttechnology.com</li>
                  <li><i className="fa-solid fa-location-dot"></i>230, Atlanta Shopping Mall, Near Sudama Chowk, Mota Varachha, Surat, 394101</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer