import React from 'react'
import '../Styles/blogCommon.css'
import blogData from '../data/blog.json'

function BlogCommon() {
    return (
        <>
            <section className='blog-part'>
                <div className="blog-container">
                    <div className="row align-items-center">
                        <div className="col-xl-10 col-lg-10 col-md-9 col-sm-12" data-aos="fade-down" data-aos-duration="1000">
                            <div className="title-part">
                                <h6>oUR LATEST NEWS</h6>
                                <h1>LEARN MORE OUR BLOG POST</h1>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-9 col-sm-12" data-aos="fade-down" data-aos-duration="1000">
                            <div className="title-part">
                                <h6>oUR LATEST NEWS</h6>
                                <h1>LEARN MORE OUR BLOG POST</h1>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12">
                            <a href="/blog" className='view-blog-all' data-aos="fade-down" data-aos-duration="1000">View All <i className="fa-solid fa-angle-right right-icon"></i></a>
                        </div>
                        {
                            blogData.slice(0, 3).map((item, index) => (
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 first" key={index} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="blog-card">
                                        <div className="blog-img">
                                            <img src={`${window.location.origin}/assets/blog/${item.image['img-1']}`} alt={item.image['img-1']} className="img-fluid" />
                                        </div>
                                        <div className="blog-date">
                                            <span>{item.date.split(",")[0].split(" ")[1]}</span>
                                            <span>{item.date.split(",")[0].split(" ")[0]}</span>
                                        </div>
                                        <div className="blog-content">
                                            <h1>{item.title}</h1>
                                            <p>{item.description}</p>
                                        </div>
                                        <button className='know-more-btn'><a href='#'>Know More</a></button>
                                    </div>

                                </div>
                            ))
                        }
                    </div>

                </div>
            </section>
        </>
    )
}

export default BlogCommon