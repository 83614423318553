import React from "react";
import Header from "../components/Header";
import "../Styles/mobileapp.css";
import mobileservice from "../data/digitalmarketing.json";
import Footer from "../components/Footer";
import Slider from "react-slick";

function Digitalmarking() {
  var slider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Header />
      <section className="S-section-1">
        <div className="home-container">
          <div className="row">
            <div
              data-aos="fade-right"
              data-aos-duration="700"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6 "
            >
              <div className="S-section1-content">
                <h2>DIGITAL MARKETING</h2>
                <p>
                  Rutilant leads the way in Digital Marketing, where strategic
                  innovation meets impactful results. Our expert team navigates
                  the dynamic digital landscape, offering comprehensive services
                  encompassing SEO, social media, and content marketing. From
                  optimizing online visibility to driving targeted engagement,
                  Rutilant's tailored solutions empower businesses to thrive in
                  the digital realm. Partner with us to unlock the full
                  potential of your online presence, as we drive growth and
                  success through the art and science of Digital Marketing.
                </p>
                <button>Talk to our expert</button>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section1-img">
                <img
                  src={require("../assets/serivce/digital.png")}
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-2">
        <div className="service-container">
          <div className="row">
            <div
              data-aos="fade-right"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section2-content">
                <h4>OUR SERVICES</h4>
                <h2>Our DIGITAL SERVICES </h2>
                <p>
                  Rutilant excels in Search Engine Optimization (SEO),
                  optimizing your online visibility for maximum impact. Our team
                  employs cutting-edge techniques to enhance website rankings,
                  drive organic traffic, and ensure your brand stands out in
                  search engine results.
                </p>
                <p>
                  Unlock the power of social media with Rutilant's Social Media
                  Marketing (SMM) expertise. We create engaging campaigns, build
                  brand awareness, and foster community engagement across
                  platforms, ensuring your business stays connected and relevant
                  in the digital social sphere.
                </p>
                <p>
                  At Rutilant, Content Marketing is more than words – it's a
                  strategic approach to captivate your audience. Our team crafts
                  compelling content that resonates with your target market,
                  driving user engagement, brand loyalty, and ultimately,
                  business growth. Partner with Rutilant for a holistic Digital
                  Marketing experience that propels your brand to new heights.
                </p>
                {/* <p>
                  Publishing the app: Native app developers publish the app on
                  the app store for users to download and install on their
                  devices.
                </p> */}
              </div>
            </div>
            <div
              data-aos="fade-left"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              {mobileservice.map((item, index) => (
                <div className="service-box" key={index}>
                  <div className="d-flex">
                    <img
                      src={`${window.location.origin}/assets/service/${item.image}`}
                      alt={item.image}
                    />
                    <h6>{item.title}</h6>
                  </div>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-3">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section3-title">
              <h4>Technology Stack</h4>
              <h2>Technologies & Platforms We Work With</h2>
            </div>
          </div>
          <div className="row">
            <div className=" col-sm-6 col-md-10 col-lg-6 col-xl-10 m-auto">
              <Slider {...slider}>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/socialmedia.png`}
                    // src={require("../assets/serivce/fluterlogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Social Media Marketing</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/searchengine.png`}
                    // src={require("../assets/serivce/nativelogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Search Engine</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/socialmedia.png`}
                    // src={require("../assets/serivce/fluterlogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Social Media Marketing</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={`${window.location.origin}/assets/service/searchengine.png`}
                    // src={require("../assets/serivce/nativelogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Search Engine</h6>
                </div>
                {/* <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/vuelogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Vue Js</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/htmllogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>HTML</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/csslogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>css</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/nativelogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>React Native</h6>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-4">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section4-title">
              <h4>OUR WORK</h4>
              <h2>digital Marketing PROJECT</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-1.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-2.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-3.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-4.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Digitalmarking;
